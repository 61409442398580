import React from 'react';
import { Helmet } from 'react-helmet';

const MetaComponent = ({ title, description, link, keywords }) => {
  const url = window.location.href;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      {link && <link rel="canonical" href={link} />}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:type" content="website" />

      {url && <meta property="og:url" content={url} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta
        property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/neuronpulse-india-super-brain.appspot.com/o/Indias-Super-Brain.png?alt=media&token=9b658564-2a1a-4fdb-bdf8-6e848c065571"
      />

      <meta property="twitter:card" content="summary_large_image" />
      {url && <meta property="twitter:url" content={url} />}
      <meta property="twitter:title" content={title} />
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      <meta
        name="twitter:image"
        content="https://firebasestorage.googleapis.com/v0/b/neuronpulse-india-super-brain.appspot.com/o/Indias-Super-Brain.png?alt=media&token=9b658564-2a1a-4fdb-bdf8-6e848c065571"
      />
    </Helmet>
  );
};
export default MetaComponent;
