import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/2023/ISB2023logo.png';
import Image from '../../../components/Image';
import Facebook from '../../../assets/facebook-isb.png';
import Insta from '../../../assets/instagram-isb.png';
import Linkedin from '../../../assets/linkedin-isb.png';
import './soon.css';
import MetaComponent from '../../../components/Meta';
const Soon2023 = () => {
  return (
    <>
      <MetaComponent
        title="Coming Soon: India's Super Brain 2023"
        description="follow us on our Social Media Platforms to get notified about the competition"
      />
      <div className="v2021">
        <header className="v2021-header">
          <Link className="check-2020" target="_blank" to="/2021">
            Check Previous ISB
          </Link>

          <Image src={logo} className="v2021-logo" alt="logo" />
          <p style={{ margin: '20px', fontSize: '40px' }}>
            Registration for ISB 2021 is over, ISB JR 2023 is coming
          </p>
          <p>
            follow us on our Social Media Platforms to get notified about the
            competition
          </p>

          <div className="v2021-social">
            <a
              href="https://www.linkedin.com/showcase/indias-super-brains"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Linkedin} alt="ISB at Linkedin" />
            </a>
            <a
              href="https://www.instagram.com/indiassuperbrain/"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Insta} alt="ISB at Instagram" />
            </a>
            <a
              href="https://www.facebook.com/indiassuperbrain"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Facebook} alt="ISB at Facebook" />
            </a>
          </div>
        </header>
      </div>
    </>
  );
};
export default Soon2023;
