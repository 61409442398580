import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/2021/isb-logo.png';
import logoWebp from '../../../assets/2021/isb-logo.webp';
import Image from '../../../components/Image';
import BlueParticleVideo from '../../../assets/2021/Blue-particle.mp4';
import Facebook from '../../../assets/facebook-isb.png';
import Insta from '../../../assets/instagram-isb.png';
import Linkedin from '../../../assets/linkedin-isb.png';
import styles from './soon.module.css';
import MetaComponent from '../../../components/Meta';

const Soon2021 = () => {
  return (
    <>
      <MetaComponent
        title="Coming Soon: India's Super Brain 2021"
        description="Follow us to get notified of this happening"
        keywords="Coming Soon, Notified"
      />
      <div className={styles.v2021}>
        <header className={styles.v2021_header}>
          <video
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              objectFit: 'cover',
              zIndex: -1
            }}
            autoPlay
            loop
            muted>
            <source src={BlueParticleVideo} type="video/mp4" />
          </video>
          <Image
            src={logo}
            className={styles.v2021_logo}
            alt="logo"
            webpImage={logoWebp}
          />
          <p>Follow us to get notified of this happening</p>

          <Link className={styles.v2021_link} to="../register">
            Register for ISB 2021
          </Link>

          <Link
            className={`${styles.floating_link} ${styles.check_2020}`}
            target="_blank"
            to="/2020">
            Check Previous ISB
          </Link>

          <Link
            className={`${styles.floating_link} ${styles.v2021_callback}`}
            to="../enquiry">
            Request a callback
          </Link>

          <div className={styles.v2021_social}>
            <a
              href="https://www.linkedin.com/showcase/indias-super-brains"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Linkedin} alt="ISB at Linkedin" />
            </a>
            <a
              href="https://www.instagram.com/indiassuperbrain/"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Insta} alt="ISB at Instagram" />
            </a>
            <a
              href="https://www.facebook.com/indiassuperbrain"
              rel="noopener noreferrer"
              target="_blank">
              <Image src={Facebook} alt="ISB at Facebook" />
            </a>
          </div>

          <div className={styles.know_more}>
            <p>
              Want to know more about what we do?{' '}
              <a
                href="https://alienbrains.in?utm_source=website&utm_medium=landing&utm_campaign=isb2020-early-bird"
                target="_blank"
                rel="noopener noreferrer">
                Know here.
              </a>
            </p>
          </div>
        </header>
      </div>
    </>
  );
};

export default Soon2021;
